define('ember-power-calendar/components/power-calendar-multiple/days', ['exports', 'ember-power-calendar/components/power-calendar/days'], function (exports, _emberPowerCalendarComponentsPowerCalendarDays) {
  exports['default'] = _emberPowerCalendarComponentsPowerCalendarDays['default'].extend({
    // Methods
    dayIsSelected: function dayIsSelected(dayMoment) {
      var calendar = arguments.length <= 1 || arguments[1] === undefined ? this.get('calendar') : arguments[1];

      var selected = calendar.selected || [];
      return selected.some(function (d) {
        return dayMoment.isSame(d, 'day');
      });
    }
  });
});