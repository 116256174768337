define('ember-power-calendar/components/power-calendar', ['exports', 'ember-power-calendar/templates/components/power-calendar', 'ember-component', 'ember-computed', 'moment', 'ember-service/inject', 'ember-concurrency'], function (exports, _emberPowerCalendarTemplatesComponentsPowerCalendar, _emberComponent, _emberComputed, _moment, _emberServiceInject, _emberConcurrency) {
  exports['default'] = _emberComponent['default'].extend({
    layout: _emberPowerCalendarTemplatesComponentsPowerCalendar['default'],
    classNames: ['ember-power-calendar'],
    classNameBindings: ['changeCenterTask.isRunning:ember-power-calendar--loading'],
    clockService: (0, _emberServiceInject['default'])('power-calendar-clock'),
    momentService: (0, _emberServiceInject['default'])('moment'),
    navComponent: 'power-calendar/nav',
    daysComponent: 'power-calendar/days',
    center: null,

    // Lifecycle chooks
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var changeCenter = function changeCenter(newCenter) {
        return _this.get('changeCenterTask').perform((0, _moment['default'])(newCenter));
      };
      this.publicActions = {
        changeCenter: changeCenter,
        moveCenter: function moveCenter(step, unit) {
          return changeCenter((0, _moment['default'])(_this.get('center')).add(step, unit));
        },
        select: function select() {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return _this.send.apply(_this, ['select'].concat(args));
        }
      };
    },

    // CPs
    currentCenter: (0, _emberComputed['default'])('center', function () {
      var center = this.get('center');
      if (center) {
        return (0, _moment['default'])(center);
      }
      return (0, _moment['default'])(this.get('selected') || this.get('clockService').getDate());
    }),

    publicAPI: (0, _emberComputed['default'])('_publicAPI', function () {
      return this.get('_publicAPI');
    }),

    _publicAPI: (0, _emberComputed['default'])('selected', 'currentCenter', 'locale', 'momentService.locale', function () {
      return {
        selected: this.get('selected'),
        center: this.get('currentCenter'),
        locale: this.get('locale') || this.get('momentService.locale') || _moment['default'].locale(),
        actions: this.get('publicActions')
      };
    }),

    // Actions
    actions: {
      select: function select(day, e) {
        var action = this.get('onSelect');
        if (action) {
          action(day, e);
        }
      }
    },

    // Tasks
    changeCenterTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(newCenterMoment) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('onCenterChange')({ date: newCenterMoment.toDate(), moment: newCenterMoment });

          case 2:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    }))
  });
});