define("ember-time-field/states/period-focused", ["exports", "ember-states/state"], function (exports, _emberStatesState) {
  exports["default"] = _emberStatesState["default"].create({
    enter: function enter(manager) {
      this.focusIn(manager);
    },

    focusIn: function focusIn(manager) {
      manager.get("input").selectPeriod();
    },

    left: function left(manager) {
      manager.transitionTo("minutes");
    },

    up: function up(manager) {
      manager.get("input").togglePeriod();
    },

    down: function down(manager) {
      manager.get("input").togglePeriod();
    },

    // TODO - intl
    key: function key(manager, code) {
      switch (code) {
        case 'A'.charCodeAt(0):
          manager.get("input").changePeriod("am");
          break;

        case 'P'.charCodeAt(0):
          manager.get("input").changePeriod("pm");
          break;
      }
    }
  });
});