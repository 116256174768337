define('ember-power-calendar/components/power-calendar-multiple', ['exports', 'ember-power-calendar/components/power-calendar', 'ember-computed', 'moment'], function (exports, _emberPowerCalendarComponentsPowerCalendar, _emberComputed, _moment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _emberPowerCalendarComponentsPowerCalendar['default'].extend({
    daysComponent: 'power-calendar-multiple/days',

    // CPs
    currentCenter: (0, _emberComputed['default'])('center', function () {
      var center = this.get('center');
      if (center) {
        return (0, _moment['default'])(center);
      }
      return (0, _moment['default'])((this.get('selected') || [])[0] || this.get('clockService').getDate());
    }),

    // Actions
    actions: {
      select: function select(day, e) {
        var action = this.get('onSelect');
        if (action) {
          action(this._buildCollection(day), e);
        }
      }
    },

    // Methods
    _buildCollection: function _buildCollection(day) {
      var selected = this.get('publicAPI.selected') || [];
      var values = [];
      var index = -1;
      for (var i = 0; i < selected.length; i++) {
        if (day.moment.isSame(selected[i], 'day')) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        values = [].concat(_toConsumableArray(selected), [day.moment]);
      } else {
        values = selected.slice(0, index).concat(selected.slice(index + 1));
      }
      var moments = values.map(function (d) {
        return (0, _moment['default'])(d);
      });
      return {
        moment: moments,
        date: moments.map(function (m) {
          return m.toDate();
        })
      };
    }
  });
});