define("ember-time-field/states/minutes-focused", ["exports", "ember-states/state", "ember-time-field/utils/codes"], function (exports, _emberStatesState, _emberTimeFieldUtilsCodes) {
  exports["default"] = _emberStatesState["default"].create({
    initialState: "digit1",

    digit1: _emberStatesState["default"].create({
      key: function key(manager, code) {
        if (!(0, _emberTimeFieldUtilsCodes.isNumberCode)(code)) {
          return; // no-op
        }

        var num = (0, _emberTimeFieldUtilsCodes.keyCodeToNumber)(code);
        manager.get("input").setMinutes(num);

        if (num <= 5) {
          manager.transitionTo("digit2");
        } else if (manager.get("input.hour12")) {
          manager.transitionTo("period");
        }
      }
    }),

    digit2: _emberStatesState["default"].create({
      key: function key(manager, code) {
        if (!(0, _emberTimeFieldUtilsCodes.isNumberCode)(code)) {
          return; // no-op
        }

        var num = (0, _emberTimeFieldUtilsCodes.keyCodeToNumber)(code);
        manager.get("input").setMinutesDigit2(num);

        if (manager.get("input.hour12")) {
          manager.transitionTo("period");
        } else {
          manager.transitionTo("digit1");
        }
      }
    }),

    enter: function enter(manager) {
      this.focusIn(manager);
    },

    focusIn: function focusIn(manager) {
      manager.get("input").selectMinutes();
    },

    left: function left(manager) {
      manager.transitionTo("hours");
    },

    right: function right(manager) {

      // TODO - better way to guard this, or not have the period state at all unless hour12 is true?
      if (manager.get("input.hour12")) {
        manager.transitionTo("period");
      }
    },

    up: function up(manager) {
      manager.get("input").incrementMinutes();
    },

    down: function down(manager) {
      manager.get("input").decrementMinutes();
    }
  });
});