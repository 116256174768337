define('ember-rollbar-client/instance-initializers/rollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var rollbarService = appInstance.lookup('service:rollbar');
    rollbarService.registerLogger();
  }

  exports.default = {
    name: 'rollbar',
    initialize: initialize
  };
});