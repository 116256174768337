define('ember-power-calendar/components/power-calendar/days', ['exports', 'ember-component', 'ember-power-calendar/templates/components/power-calendar/days', 'ember-computed', 'ember-service/inject', 'ember-runloop', 'moment'], function (exports, _emberComponent, _emberPowerCalendarTemplatesComponentsPowerCalendarDays, _emberComputed, _emberServiceInject, _emberRunloop, _moment) {

  function withLocale(locale, fn) {
    var returnValue = undefined;
    if (locale) {
      var previousLocale = _moment['default'].locale();
      _moment['default'].locale(locale);
      returnValue = fn();
      _moment['default'].locale(previousLocale);
    } else {
      returnValue = fn();
    }
    return returnValue;
  }

  exports['default'] = _emberComponent['default'].extend({
    layout: _emberPowerCalendarTemplatesComponentsPowerCalendarDays['default'],
    focusedId: null,
    showDaysAround: true,
    weekdayFormat: 'short', // "min" | "short" | "long"
    clockService: (0, _emberServiceInject['default'])('power-calendar-clock'),

    // CPs
    weekdaysMin: (0, _emberComputed['default'])('calendar.locale', function () {
      return withLocale(this.get('calendar.locale'), function () {
        return _moment['default'].weekdaysMin();
      });
    }),

    weekdaysShort: (0, _emberComputed['default'])('calendar.locale', function () {
      return withLocale(this.get('calendar.locale'), function () {
        return _moment['default'].weekdaysShort();
      });
    }),

    weekdays: (0, _emberComputed['default'])('calendar.locale', function () {
      return withLocale(this.get('calendar.locale'), function () {
        return _moment['default'].weekdays();
      });
    }),

    localeStartOfWeek: (0, _emberComputed['default'])('weekdaysShort', 'startOfWeek', function () {
      var forcedStartOfWeek = this.get('startOfWeek');
      if (forcedStartOfWeek) {
        return parseInt(forcedStartOfWeek, 10);
      }
      var now = this.get('clockService').getDate();
      var dayAbbr = withLocale(this.get('calendar.locale'), function () {
        return (0, _moment['default'])(now).startOf('week').format('ddd');
      });
      return this.get('weekdaysShort').indexOf(dayAbbr);
    }),

    weekdaysNames: (0, _emberComputed['default'])('localeStartOfWeek', 'weekdayFormat', 'calendar.locale', function () {
      var _getProperties = this.getProperties('localeStartOfWeek', 'weekdayFormat');

      var localeStartOfWeek = _getProperties.localeStartOfWeek;
      var weekdayFormat = _getProperties.weekdayFormat;

      var format = 'weekdays' + (weekdayFormat === 'long' ? '' : weekdayFormat === 'min' ? 'Min' : 'Short');
      var weekdaysNames = this.get(format);
      return weekdaysNames.slice(localeStartOfWeek).concat(weekdaysNames.slice(0, localeStartOfWeek));
    }),

    days: (0, _emberComputed['default'])('calendar', 'focusedId', 'localeStartOfWeek', 'minDate', 'maxDate', function () {
      var today = this.get('clockService').getDate();
      var calendar = this.get('calendar');
      var lastDay = this.lastDay(calendar);
      var currentMoment = this.firstDay(calendar);
      var days = [];
      while (currentMoment.isBefore(lastDay)) {
        days.push(this.buildDay(currentMoment, today, calendar));
        currentMoment.add(1, 'day');
      }
      return days;
    }),

    weeks: (0, _emberComputed['default'])('showDaysAround', 'days', function () {
      var _getProperties2 = this.getProperties('showDaysAround', 'days');

      var showDaysAround = _getProperties2.showDaysAround;
      var days = _getProperties2.days;

      var weeks = [];
      var i = 0;
      while (days[i]) {
        var daysOfWeek = days.slice(i, i + 7);
        if (!showDaysAround) {
          daysOfWeek = daysOfWeek.filter(function (d) {
            return d.isCurrentMonth;
          });
        }
        weeks.push({
          id: days[0].moment.format('YYYY-w'),
          days: daysOfWeek,
          missingDays: 7 - daysOfWeek.length
        });
        i += 7;
      }
      return weeks;
    }),

    // Actions
    actions: {
      onFocusDay: function onFocusDay(day) {
        (0, _emberRunloop.scheduleOnce)('actions', this, this._updateFocused, day.id);
      },

      onBlurDay: function onBlurDay() {
        (0, _emberRunloop.scheduleOnce)('actions', this, this._updateFocused, null);
      },

      onKeyDown: function onKeyDown(e) {
        var focusedId = this.get('focusedId');
        if (focusedId) {
          var days = this.get('days');
          var day = undefined,
              index = undefined;
          for (var i = 0; i < days.length; i++) {
            if (days[i].id === focusedId) {
              index = i;
              break;
            }
          }
          if (e.keyCode === 38) {
            e.preventDefault();
            var newIndex = Math.max(index - 7, 0);
            day = days[newIndex];
            if (day.isDisabled) {
              for (var i = newIndex + 1; i <= index; i++) {
                day = days[i];
                if (!day.isDisabled) {
                  break;
                }
              }
            }
          } else if (e.keyCode === 40) {
            e.preventDefault();
            var newIndex = Math.min(index + 7, days.length - 1);
            day = days[newIndex];
            if (day.isDisabled) {
              for (var i = newIndex - 1; i >= index; i--) {
                day = days[i];
                if (!day.isDisabled) {
                  break;
                }
              }
            }
          } else if (e.keyCode === 37) {
            day = days[Math.max(index - 1, 0)];
            if (day.isDisabled) {
              return;
            }
          } else if (e.keyCode === 39) {
            day = days[Math.min(index + 1, days.length - 1)];
            if (day.isDisabled) {
              return;
            }
          } else {
            return;
          }
          this.set('focusedId', day.id);
          (0, _emberRunloop.scheduleOnce)('afterRender', this, '_focusDate', day.id);
        }
      }
    },

    // Methods
    buildDay: function buildDay(dayMoment, today, calendar) {
      var id = dayMoment.format('YYYY-MM-DD');
      var momentDate = dayMoment.clone();
      var isDisabled = !this.get('onSelect');
      if (!isDisabled) {
        var minDate = this.get('minDate');
        if (minDate && momentDate.isBefore(minDate)) {
          isDisabled = true;
        }
        if (!isDisabled) {
          var maxDate = this.get('maxDate');
          if (maxDate && momentDate.isAfter(maxDate)) {
            isDisabled = true;
          }
        }
      }
      return {
        id: id,
        number: momentDate.date(),
        date: momentDate._d,
        moment: momentDate,
        isDisabled: isDisabled,
        isFocused: this.get('focusedId') === id,
        isCurrentMonth: momentDate.month() === calendar.center.month(),
        isToday: momentDate.isSame(today, 'day'),
        isSelected: this.dayIsSelected(momentDate, calendar)
      };
    },

    buildonSelectValue: function buildonSelectValue(day) {
      return day;
    },

    dayIsSelected: function dayIsSelected(dayMoment) {
      var calendar = arguments.length <= 1 || arguments[1] === undefined ? this.get('calendar') : arguments[1];

      return calendar.selected ? dayMoment.isSame(calendar.selected, 'day') : false;
    },

    firstDay: function firstDay(calendar) {
      var firstDay = calendar.center.clone().startOf('month');
      var localeStartOfWeek = this.get('localeStartOfWeek');
      while (firstDay.isoWeekday() % 7 !== localeStartOfWeek) {
        firstDay.add(-1, 'day');
      }
      return firstDay;
    },

    lastDay: function lastDay(calendar) {
      var localeStartOfWeek = this.get('localeStartOfWeek');
      var lastDay = calendar.center.clone().endOf('month');
      var localeEndOfWeek = (localeStartOfWeek + 6) % 7;
      while (lastDay.isoWeekday() % 7 !== localeEndOfWeek) {
        lastDay.add(1, 'day');
      }
      return lastDay;
    },

    _updateFocused: function _updateFocused(id) {
      this.set('focusedId', id);
    },

    _focusDate: function _focusDate(id) {
      var dayElement = this.element.querySelector('[data-date="' + id + '"]');
      if (dayElement) {
        dayElement.focus();
      }
    }
  });
});