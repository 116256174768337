define("ember-time-field/utils/pad", ["exports"], function (exports) {
  exports["default"] = pad;

  function pad(val) {
    var str = String(val);
    if (str.length === 1) {
      return "0" + str;
    } else {
      return str;
    }
  }
});