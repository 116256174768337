define("ember-time-field/utils/codes", ["exports"], function (exports) {
  exports.isNumberCode = isNumberCode;
  exports.keyCodeToNumber = keyCodeToNumber;
  var NUM_KEYS_START = 48;
  var NUM_KEYS_END = 57;
  var NUM_PAD_START = 96;
  var NUM_PAD_END = 105;

  var KEY_CODES = {
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    TAB: 9
  };

  exports.KEY_CODES = KEY_CODES;

  function isNumberCode(code) {
    return code >= NUM_KEYS_START && code <= NUM_KEYS_END || code >= NUM_PAD_START && code <= NUM_PAD_END;
  }

  function keyCodeToNumber(code) {
    if (code >= NUM_KEYS_START && code <= NUM_KEYS_END) {
      return code - NUM_KEYS_START;
    } else if (code >= NUM_PAD_START && code <= NUM_PAD_END) {
      return code - NUM_PAD_START;
    } else {
      return null;
    }
  }
});