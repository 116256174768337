define('ember-power-calendar/components/power-calendar-range', ['exports', 'ember-power-calendar/components/power-calendar', 'ember-computed', 'moment', 'ember-metal/get', 'ember-platform'], function (exports, _emberPowerCalendarComponentsPowerCalendar, _emberComputed, _moment, _emberMetalGet, _emberPlatform) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  function fallbackIfUndefined(fallback) {
    return (0, _emberComputed['default'])({
      get: function get() {
        return fallback;
      },
      set: function set(_, v) {
        return v === undefined ? fallback : v;
      }
    });
  }

  function parseDuration(value) {
    if (value === null || _moment['default'].isDuration(value)) {
      return value;
    }
    if (typeof value === 'number') {
      return _moment['default'].duration(value, 'days');
    }
    if (typeof value === 'string') {
      var _value$match = value.match(/(\d+)(.*)/);

      var _value$match2 = _slicedToArray(_value$match, 3);

      var quantity = _value$match2[1];
      var units = _value$match2[2];

      units = units.trim() || 'days';
      return _moment['default'].duration(parseInt(quantity, 10), units);
    }
  }

  exports['default'] = _emberPowerCalendarComponentsPowerCalendar['default'].extend({
    daysComponent: 'power-calendar-range/days',
    minRange: fallbackIfUndefined(_moment['default'].duration(1, 'day')),
    maxRange: fallbackIfUndefined(null),

    // CPs
    currentCenter: (0, _emberComputed['default'])('center', function () {
      var center = this.get('center');
      if (center) {
        return (0, _moment['default'])(center);
      }
      return (0, _moment['default'])(this.get('selected.start') || this.get('clockService').getDate());
    }),

    minRangeDuration: (0, _emberComputed['default'])('minRange', function () {
      return parseDuration(this.get('minRange'));
    }),

    maxRangeDuration: (0, _emberComputed['default'])('maxRange', function () {
      return parseDuration(this.get('maxRange'));
    }),

    publicAPI: (0, _emberComputed['default'])('_publicAPI', 'minRangeDuration', 'maxRangeDuration', function () {
      var rangeOnlyAPI = { minRange: this.get('minRangeDuration'), maxRange: this.get('maxRangeDuration') };
      return (0, _emberPlatform.assign)(rangeOnlyAPI, this.get('_publicAPI'));
    }),

    // Actions
    actions: {
      select: function select(day, e) {
        var range = this._buildRange(day);
        var _range$moment = range.moment;
        var start = _range$moment.start;
        var end = _range$moment.end;

        if (start && end) {
          var _get = this.get('publicAPI');

          var minRange = _get.minRange;
          var maxRange = _get.maxRange;

          var diff = Math.abs(end.diff(start));
          if (diff < minRange.as('ms') || maxRange && diff > maxRange.as('ms')) {
            return;
          }
        }
        var action = this.get('onSelect');
        if (action) {
          action(range, e);
        }
      }
    },

    // Methods
    _buildRange: function _buildRange(day) {
      var selected = this.get('publicAPI.selected') || { start: null, end: null };

      var _getProperties = (0, _emberMetalGet.getProperties)(selected, 'start', 'end');

      var start = _getProperties.start;
      var end = _getProperties.end;

      if (start && !end) {
        var startMoment = (0, _moment['default'])(start);
        if (startMoment.isAfter(day.moment)) {
          return {
            moment: { start: day.moment, end: startMoment },
            date: { start: day.date, end: startMoment._d }
          };
        } else {
          return {
            moment: { start: startMoment, end: day.moment },
            date: { start: startMoment._d, end: day.date }
          };
        }
      } else {
        return {
          moment: { start: day.moment, end: null },
          date: { start: day.date, end: null }
        };
      }
    }
  });
});