define('ember-rollbar-client/services/rollbar', ['exports', 'rollbar'], function (exports, _rollbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentUser: null,
    enabled: Ember.computed.readOnly('config.enabled'),

    notifier: Ember.computed(function () {
      return this.rollbarClient();
    }),

    config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment').emberRollbarClient;
    }),

    rollbarClient: function rollbarClient() {
      var customConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var config = Ember.assign(this.get('config'), customConfig);
      return new _rollbar.default(config);
    },


    // Observers

    currentUserChanged: Ember.observer('currentUser', function () {
      return this.get('notifier').configure({ payload: { person: this.get('currentUser') } });
    }),

    enabledChanged: Ember.observer('enabled', function () {
      return this.get('notifier').configure({ enabled: this.get('enabled') });
    }),

    // Notifications

    critical: function critical(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').critical(message, data);
    },
    error: function error(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').error(message, data);
    },
    warning: function warning(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').warning(message, data);
    },
    info: function info(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').info(message, data);
    },
    debug: function debug(message) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.get('notifier').debug(message, data);
    },
    registerLogger: function registerLogger() {
      var _this = this;

      if (this.get('enabled')) {
        var oldOnError = Ember.onerror || function () {};

        Ember.onerror = function () {
          oldOnError.apply(undefined, arguments);
          _this.error.apply(_this, arguments);
        };
      }
    }
  });
});